









import { Component, Vue } from 'vue-property-decorator'

export enum PREVIEW_COMPONENT {
  QUESTION = 'Question.vue',
  ANSWER = 'Answer.vue'
}

@Component
export default class Preview extends Vue {
  previewComponent = PREVIEW_COMPONENT.QUESTION

  previewComponentProps = {}

  async created () {
    const { hash } = this.$route.query

    if (hash) {
      const { id = null } = JSON.parse(decodeURIComponent(escape(atob(hash as string))))

      if (id) {
        this.previewComponentProps = {
          questionId: id
        }
      }
    }
  }

  get currentComponent () {
    const components = {
      [PREVIEW_COMPONENT.QUESTION]: () => import('../views/Question.vue'),
      [PREVIEW_COMPONENT.ANSWER]: () => import('../views/Answer.vue')
    }

    const { [this.previewComponent]: component = null } = components

    return component
  }

  changePreview (component: PREVIEW_COMPONENT, data = {}) {
    this.previewComponent = component

    this.previewComponentProps = data
  }
}
